<template>
  <div class="product">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <NavBar />
    <IntroduceEMG />
    <Product2 />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import IntroduceEMG from "@/components/IntroduceEMG.vue";
import Product2 from "@/components/Product2.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Product",
  components: {
    NavBar,
    IntroduceEMG,
    Product2,
    Footer,
  },
};
</script>