
<template>
  <div>
    <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ inforProduct.nameProduct }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <img class="modal-image" :src="inforProduct.linkImage" />
          <div
            class="modal-body ml-4 mr-4"
            v-html="inforProduct.desProduct"
          ></div>
          <!-- <p class="modal-body" v-html="inforProduct.desProduct"></p> -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <section id="product" class="services-area pt-40 pb-20 gray-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center pb-0">
              <h5 class="sub-title">Sản phẩm tiêu biểu</h5>
            </div>
            <!-- section title -->
          </div>
        </div>
        <div class="row justify-content-center">
        
          <div
            class="col-lg-4 col-md-5 col-sm-7"
            v-for="(product, index) in products"
            :key="index"
            data-toggle="modal"
            data-target="#exampleModalLong"
            v-on:click="inforProduct = product"
          > 
            <div
              class="single-services text-center mt-30 wow fadeInUp gray-bg"
              style="height:300px"
              data-wow-duration="1.5s"
              data-wow-delay="0.4s"
            >
              <div class="services-icon">
                <i class="lni-paint-roller">
                  <img
                    v-bind:src="product.linkImage"
                    style="max-height: 150px"
                  />
                </i>
              </div>
              <div class="services-content mt-15">
                <h4 class="services-title">{{ product.nameProduct }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </section>
  </div>
</template>

<script>
import { products } from "../firebase-config";
// import router from 'vue-router'
export default {
  data() {
    return {
      productShow: [],
      products: [],
      inforProduct: {},
      // checkShowHide: this.checkShowProduct
    };
  },
  methods: {
    fetchData() {
      products.get().then((res) => {
        this.products = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
</style>